:root {
  --select: #41424C;
  --selectGlow: #7d7a7a;
  --mainBackground: #e8dedc;
  --secondaryColor: Ivory;
}

.App {
  text-align: center;
  width: 100vw;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--mainBackground);
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(20px + 4vmin);
  color: var(--select);
  padding-top: 1%;
  caret-color: transparent;
}

.App-link {
  color: #61dafb;
}

.App-info {
  font-size: 2vw;
}

.linkOut {
  font-family: blossom;
    color: var(--select);
  font-size: calc(10px + 2vmin);
  padding-bottom: 0.25%;
}

@media screen and (max-width: 900px) { 

}